import * as React from "react";
import {
  Row,
  Text,
  Col,
  Button,
  FormControlContext,
} from "@perfocal/UIKit";
import "@perfocal/UIKit/form/payment.less";
import { Package } from "../../models/package.model";
import { Addon } from "../../models/addon.model";
import { PromocodeResource } from "../../resources/promocode.resource";
import { SegmentResource } from "../../resources/segment.resource";
import { Scene } from "../../models/scene.model";

interface CheckoutFormProps {
  context: FormControlContext,
  promocode?: string;
  payment_intent_id: string;
  package: Package;
  scene: Scene;
  addons: Addon[];
}

interface CheckoutFormState {
  items: CheckoutItem[];
  promocode?: string;
  discount?: number;
  final_amount: number;
  error: boolean;
}

interface CheckoutItem {
  name: string;
  price: number;
}

export class CheckoutForm extends React.Component<CheckoutFormProps, CheckoutFormState> implements FormControlContext {
  MINIMUM_VALUE = 30
  promocodeResource: PromocodeResource;
  segmentResource: SegmentResource;

  constructor(props: CheckoutFormProps) {
    super(props);
    this.state = {
      items: [],
      discount: 0,
      promocode: null,
      final_amount: null,
      error: null
    };

    this.segmentResource = new SegmentResource();
    this.promocodeResource = new PromocodeResource();

    this.onKeyPress = this.onKeyPress.bind(this);
  }

  async componentDidUpdate(prevProps: CheckoutFormProps) {
    if (this.props.payment_intent_id != prevProps.payment_intent_id || this.props.promocode != prevProps.promocode) {
      //Automatically apply referral code
      if(this.props.promocode && this.props.payment_intent_id) {
        await this.setState({promocode: this.props.promocode})
        this.onAction('promocode')
      }
    }
  }

  async componentDidMount() {

    const items: CheckoutItem[] = []
    items.push({
      name: `${this.props.scene.name} - ${this.props.package.name}`,
      price: this.props.package.price
    })
    this.props.addons.forEach(addon => {
      items.push({
        name: addon.name,
        price: addon.price
      })
    })
    const value: number = items.reduce((n, item) => n + item.price, 0);

    this.setState({items: items, final_amount: value})
  }

  onKeyPress(e: React.KeyboardEvent<HTMLFormElement>) {
    if (e.key === "Enter") {
      e.preventDefault();
      this.onAction("promocode");
    }
  }

  async onAction(action: string) {
    switch (action) {
    case "promocode":
      try {
        
        const payment = await this.promocodeResource.applyPromocode({
          promocode: this.state.promocode,
          payment_intent_id: this.props.payment_intent_id
        })

        this.setState({
          error: false,
          discount: payment.discount
        })

        this.props.context.onValue('payment', payment, true)
        this.segmentResource.segmentTrack("Promocode Success", {promocode: this.state.promocode})
      }
      catch(e){
        this.setState({
          error: true
        })
        this.segmentResource.segmentTrack("Promocode Denied", {promocode: this.state.promocode})
      }
    }
  }

  isPromoValidMsg() {
    const {error} = this.state;
    if (error == false) {
      return 'Promocode applied successfully';
    } else if (error == true){
      return "Invalid code, please try again.";
    } else {
      return ''
    }
  }

  onValue(field: string, value: any, isValid: boolean): void {
    switch (field) {
      case "promocode":
        this.setState({promocode: value})
    }
  }

  render() {
    const {items, discount} = this.state;
    const subtotal = this.state.final_amount;
    const total = Math.max(subtotal - (discount || 0), 0);
    const msg = this.isPromoValidMsg();

    const classNames = [];
    if (msg == "Invalid code, please try again.") {
      classNames.push("color--error fadein");
    } else {
      classNames.push("color--success fadein");
    }

    let msgTwo;
    if(total === 30) {
      msgTwo = '* miumum charge amount'
    }
   
    const freeItems = [];
    freeItems.push({name: 'Standard Image Editing', price: 'Included'})
    if(!this.props.addons.some(item => item.name === '24hr Delivery')){
      freeItems.push({name: '48hr Delivery', price: 'Included'})
    }

    return (
      <form onKeyPress={this.onKeyPress}>
        <Row>
        <Col xs={12} style={{paddingTop: 0}} >
          <ol className="payment breakdown">
            {
              items.map(item => {
                if (item.name === "Special Offer") {
                  return (
                    <li key={item.name}>
                        <span className="color--success">{item.name}</span>
                        <span className="color--success" style={{margin: 0, float: "right"}}>
                          -£{Math.abs(item.price) / 100}
                        </span>
                    </li>
                  );
                } else if (item.name === "Fee Adjustment") {
                  return (
                    <li key={item.name}>
                        <span className="entry">{item.name}</span>
                        <span className="currency">{item.price / 100}</span>
                    </li>
                  );
                } else {
                  return (
                    <li key={item.name}>
                        <span className="entry">{item.name}</span>
                        <span className="currency">{item.price / 100}</span>
                    </li>
                  );
                }
              })
            }
            {freeItems.map((item) => {
              return(
                <li key={item.name}>
                  <span className="entry">{item.name}</span>
                  <span className="color--success type--bold" style={{margin: 0, float: "right"}}>{item.price}</span>
                </li>
              )
            })}
            <hr className="short"/>
              {
                (discount > 0) && (
                  <li>
                    <span className="entry" style={{fontWeight: 'bold'}}>Subtotal</span>
                    <span className="currency"  style={{fontWeight: 'bold'}}>{(total / 100) + (discount/100)}</span>
                  </li>
                )
              }
               {
                (discount > 0) && (
                  <li>
                    <span className="entry">Discount</span>
                    <span className="color--success fadein" style={{margin: 0, lineHeight: "40px", float: "right"}}>
                      - £{discount / 100}
                    </span>
                    <hr className="short"/>
                  </li>
                )
              }
            <li>
              <Row collapsed>
                <Col xs={7}>
                  <Text field="promocode"
                        placeholder="Promocode?"
                        validateFn={(value) => !/^\s*$/.test(value)}
                        value={this.state.promocode}
                        error="Invalid Promocode"
                        context={this}/>
                </Col>
                <Col xs={5}>
                  <Button className="btn btn--primary-1 box-shadow-button" action="promocode" context={this}>
                    Apply
                  </Button>
                </Col>
              </Row>
            </li>
            <li>
                <span className={classNames.join(" ")} style={{margin: 0, lineHeight: "40px"}}>
                  {msg}
                </span>
            </li>
            <hr/>
            <li className="subtotal">
              <span className="entry">Total</span>
              {
                (total > this.MINIMUM_VALUE) ?
                  <span className="currency">{total / 100}</span>
                :
                  <span className="free">Free</span>
              }
            </li>
            <li>
            <span className='color--error fadein' style={{float: 'right', margin: 0, lineHeight: "40px"}}>
                  {msgTwo}
                </span>
            </li>
          </ol>
        </Col>
        </Row>
      </form>
    );
  }
}