import React from 'react';
import {
  Col,
  Radio,
  Row,
  FormControlContext,
  RadioOption,
  OptionProps,
} from '@perfocal/UIKit';
import { Package } from '../../models/package.model';
import Carousel, { CarouselCellAlignProp } from 'nuka-carousel';
import { isEqual } from 'lodash';
import { SegmentResource } from '../../resources/segment.resource';


interface PackageFormProps {
  context: FormControlContext;
  packages_list: Package[];
  package: Package;
  label: string;
}

interface PackageFormState {
  package_list: { [key: number]: Package };
  package: Package;
}

export class PackageForm
  extends React.Component<PackageFormProps, PackageFormState>
  implements FormControlContext {
  segmentResource: SegmentResource
  PURPOSES = ['Business', 'Personal'];

  constructor(props: PackageFormProps) {
    super(props);

    const initialState: PackageFormState = {
      package_list: {},
      package: props.package || {} as Package,
    };

    this.state = initialState;

    this.segmentResource = new SegmentResource()
  }

  async componentDidMount() {
    const mappedPackages: { [key: number]: Package } = {};

    this.props.packages_list.forEach(pckg => {
      mappedPackages[pckg.id] = pckg;
    });

    this.setState({
      package_list: mappedPackages,
      package: this.props.package || {} as Package
    });
  }

  async componentDidUpdate(prevProps: PackageFormProps) {
    if (!isEqual(this.props.packages_list, prevProps.packages_list)) {
      this.componentDidMount();
    } else if (this.props.package !== prevProps.package) {
      this.setState({ package: this.props.package })
    }
  }

  async onValue(field: string, value: any, isValid: boolean) {
    if (!isValid) {
      this.props.context.onValue(field, value, false);
    }

    switch (field) {
      case 'package':
        value = this.state.package_list[value];
        this.segmentResource.segmentTrack('Price Clicked', { package_id: `${value.id}` })
        break;
    }
    this.setState({ [field]: value } as PackageFormState);
    this.props.context.onValue(field, value, true);
  }

  onAction() { }

  render() {
    return (
      <form>
        <h4 className="color--primary text-center">{this.props.label}</h4>
        <Row>
          <Col xs={12}>
            <PackageList
              field="package"
              value={String(this.state.package.id)}
              context={this}
            >
              {Object.values(this.state.package_list).map(pckg => {
                return (
                  <PackageOption
                    value={String(pckg.id)}
                    item={pckg}
                    key={pckg.id}
                  />
                );
              })}
            </PackageList>
          </Col>
        </Row>
      </form>
    );
  }
}

class PackageList extends Radio {
  constructor(props: any) {
    super(props);
  }
  render() {
    const valueIndex: any = this.state.valueIndex;
    const value: any = this.state.value;
    let slideWidth;
    if (window.innerWidth >= 990) {
      slideWidth = 0.285;
    } else if (window.innerWidth < 990 && window.innerWidth >= 610) {
      slideWidth = 0.33;
    } else if (window.innerWidth < 610 && window.innerWidth >= 480) {
      slideWidth = 0.45;
    } else if (window.innerWidth < 480 && window.innerWidth >= 420) {
      slideWidth = 0.55;
    } else {
      slideWidth = 0.6;
    }
    let cellAlign;
    if (
      valueIndex[value] === Object.keys(valueIndex).length - 1 &&
      Object.keys(valueIndex).length !== 1
    ) {
      cellAlign = 'right';
    } else if (
      valueIndex[value] === 0 &&
      Object.keys(valueIndex).length !== 1
    ) {
      cellAlign = 'left';
    } else if (
      (valueIndex[value] > 0 &&
        valueIndex[value] < Object.keys(valueIndex).length - 1) ||
      Object.keys(valueIndex).length === 1
    ) {
      cellAlign = 'center';
    } else {
      cellAlign = 'left';
    }
    return (
      <div id="pricing">
        <div className="slides">
          <Carousel
            edgeEasing="easeBackOut"
            easing="easeCubicInOut"
            cellAlign={cellAlign as CarouselCellAlignProp}
            speed={700}
            slideIndex={valueIndex[value]}
            slideWidth={slideWidth}
            cellSpacing={15}
            framePadding="10px"
            frameOverflow="visible"
            renderCenterLeftControls={({ previousSlide }) => (
              <button
                className="flickity-prev-next-button previous"
                type="button"
                onClick={previousSlide}
              >
                <svg viewBox="0 0 100 100">
                  <path
                    d="M 10,50 L 60,100 L 70,90 L 30,50  L 70,10 L 60,0 Z"
                    className="arrow"
                  ></path>
                </svg>
              </button>
            )}
            renderCenterRightControls={({ nextSlide }) => (
              <button
                className="flickity-prev-next-button next"
                type="button"
                onClick={nextSlide}
              >
                <svg viewBox="0 0 100 100">
                  <path
                    d="M 10,50 L 60,100 L 70,90 L 30,50  L 70,10 L 60,0 Z"
                    className="arrow"
                    transform="translate(100, 100) rotate(180) "
                  ></path>
                </svg>
              </button>
            )}
            renderBottomCenterControls={() => null}
          >
            {React.Children.map(this.props.children, (original, key) => {
              const child = original as any;
              const v = child['props'].value;
              const active = v === value;
              return React.cloneElement(child, {
                key: String(key),
                context: this,
                active,
              });
            })}
          </Carousel>
        </div>
      </div>
    );
  }
}

type PackageOptionProps = {
  item: Package;
} & OptionProps;
/**
 * Component to display a single package item
 */
class PackageOption extends RadioOption<PackageOptionProps> {
  render() {
    const { id, field } = this.state;
    const { active, item } = this.props;
    const classNames = [
      'package-option',
      'boxed',
      'box-shadow-shallow',
      'text-center',
    ];
    if (active) {
      classNames.push('is-selected');
      classNames.push('box-shadow-wide');
    }
    if (item.most_popular) {
      classNames.push('most-popular');
    }
    return (
      <div>
        <label htmlFor={id} style={{ paddingTop: 0, width: '100%' }}>
          <div className={classNames.join(' ')}>
            <input
              id={id}
              type="radio"
              style={{ display: 'none' }}
              name={field}
              value={this.props.value}
              onChange={this.onChange}
            />
            <div className="package">
              <h4>
                {item.name}
                <span className="tooltip" data-tooltip-direction="bottom" >
                  <div className="tooltip__anchor"></div>
                  <div className="tooltip__text">
                    - {item.description.split('\\n')[0].slice(2)}
                    <br />- Minimum of {
                      item.description.match(/(\d+)\+/)[1]
                    }{' '}
                    edited photos guaranteed
                  </div>
                </span>
              </h4>
              <span className="text-left h1 mb-0 ">
                <span className="pricing__dollar">&pound;</span>
                {item.price / 100}
              </span>
              <hr />
              <div
                className="text-left"
                style={{ marginBottom: '1.85em', fontWeight: 400 }}
              >
                <span>
                  <strong>{item.duration.split(' ')[0]}</strong>
                </span>{' '}
                {item.duration.split(' ')[1]} shoot
                <br />
                <span>
                  <strong>Unlimited</strong>&nbsp;
                </span>
                <span>photos</span>
                <br />
                <span>
                  <strong>Editing</strong>&nbsp;
                </span>
                <span>included</span>
                <br />
              </div>
            </div>
            {(() => {
              if (active) {
                return (
                  <a className="btn btn--primary-2 box-shadow-button">
                    <span className="btn__text">Selected</span>
                  </a>
                );
              } else {
                return (
                  <a className="btn btn--primary box-shadow-button">
                    <span className="btn__text">Select</span>
                  </a>
                );
              }
            })()}
          </div>
        </label>
      </div>
    );
  }
}
