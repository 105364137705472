import { ZapierDto } from "./dto/zapier/zapier.dto";


export class ZapierResource {

  async zapierPost(payload: ZapierDto): Promise<any> {
    if(process.env.NODE_ENV === 'production') {
      try {
        var xhr = new XMLHttpRequest();
        xhr.open("POST", 'https://hooks.zapier.com/hooks/catch/5026744/orb4ub4/');
        xhr.send(JSON.stringify(payload));
      } catch(e) {
        console.error(e);
      } 
    } else {
      return
    }
  }
}

 
