import React from 'react';
import { BookingFormContext } from '../../context/booking-form';
import { RouteComponentProps } from '@reach/router';
import { Button, Col, Row, FormControlContext, Text } from '@perfocal/UIKit';
import { DateForm } from './date-form';
import { AddonsForm } from './addons-form';
import { AddressForm } from './address-form';
import ProgressContext from '../../context/ProgressContext';
import { Addon } from '../../models/addon.model';
import { SegmentResource } from '../../resources/segment.resource';

interface PhotographerFormSectionProps extends RouteComponentProps {
  children?: React.ReactNode;
  progress: ProgressContext;
  booking: BookingFormContext;
}

interface PhotographerFormSectionState {
  date: Date;
  hour: number;
  minute: number;
  additional_details: string;
  addons: Addon[];
  address: AddressType;
  showErrors: boolean;
  errorMessages: string[]
}

interface AddressType {
  addressText: string;
  lat: number;
  lng: number;
  city: string;
  postcode: string;
}

export class PhotographerFormSection
  extends React.Component<
    PhotographerFormSectionProps,
    PhotographerFormSectionState
  >
  implements FormControlContext {
  segmentResource: SegmentResource

  constructor(props: PhotographerFormSectionProps) {
    super(props);

    const initialState: PhotographerFormSectionState = {
      date: props.booking.shoot.starts_at || null,
      hour: props.booking.shoot.starts_at? props.booking.shoot.starts_at.getHours() : null,
      minute: props.booking.shoot.starts_at? props.booking.shoot.starts_at.getMinutes() : null,
      additional_details: props.booking.shoot.additional_details,
      addons: props.booking.shoot.addons || [],
      address: {
        addressText: props.booking.shoot.address || null,
        lat: props.booking.shoot.latitude || 51.5073509, //London lat
        lng: props.booking.shoot.longitude || -0.12775829999998223, //London long
        city: props.booking.shoot.city || null,
        postcode: props.booking.shoot.postcode || null
      } as AddressType,
      showErrors: false,
      errorMessages: null
    };

    this.state = initialState;
    this.segmentResource = new SegmentResource()
  }

  componentDidMount() {
    this.props.booking.turnOffLoader();

    this.segmentResource.segmentLoad();

    this.segmentResource.segmentTrack('Viewed Checkout Step', { step: 2 })
  }

  checkForm(): boolean {
    const { address, date, hour, minute } = this.state;
    let errorMessages = []
    !address.addressText ? errorMessages.push('Please fill in the address'): null
    date == null || hour == null || minute == null ? errorMessages.push('Please enter a date and time') : null
    this.setState({errorMessages})
    return date != null && hour != null && minute != null && !!address.addressText
  }

  async onValue(field: string, value: any, isValid: boolean) {
    if (isValid) {
      let date = new Date(this.state.date);
      switch (field) {
        case 'hour':
          value.endsWith('PM') && value !== '12 PM'
            ? (value = Number(value.slice(0, -2)) + 12)
            : (value = Number(value.slice(0, -2)));
          date.setHours(value);
          this.setState({ date, hour: value });
          this.props.booking.shoot.starts_at = date
          break;
        case 'minute':
          date.setMinutes(value);
          this.setState({ date, minute: value });
          this.props.booking.shoot.starts_at = date
          break;
        case 'date':
          this.setState({ date: value });
          this.props.booking.shoot.starts_at = value
          break;
        case 'address':
          this.setState({ address: value })
          this.props.booking.shoot.address = this.state.address.addressText;
          this.props.booking.shoot.latitude = this.state.address.lat;
          this.props.booking.shoot.city = this.state.address.city;
          this.props.booking.shoot.longitude = this.state.address.lng;
          this.props.booking.shoot.postcode = this.state.address.postcode;  
          break;
        case 'addons':
        case 'additional_details':
          this.setState({ [field]: value } as PhotographerFormSectionState);
          this.props.booking.shoot[field] = value
        default:
      }
      this.state.showErrors ? this.checkForm() : null
    }
  }

  onAction(action: string) {
    switch (action) {
      case 'step3':
        if(this.checkForm()) {
          let addonObject: any = {};
          this.props.booking.shoot.package.addons.forEach(addon => {
            let addon_id = `addon_${addon.id}`;
            addonObject[addon_id] = false;
            if(this.state.addons && this.state.addons.includes(addon)){
              addonObject[addon_id] = true;
            }
            return addonObject;
          })
                   
          this.segmentResource.segmentTrack('Completed Checkout Step', { 
            step: 2,  
            city: `${this.props.booking.shoot.city}`,
            addons: addonObject
          })
  
          this.props.progress.next();
        } else {
          this.setState({showErrors: true})
        }
        break;
    }
  }

  render() {
    let errorList;
    if (this.state.showErrors) {
      errorList = this.state.errorMessages.map((error, key) => {
        return <li key={key}><span className="color--error">{error}</span></li>;
      })
    }

    return (
      <section className='unpad--bottom'>
        <Row>
          <Col
            xs={12}
            md={8}
            mdOffset={2}
            className="boxed box-shadow-wide"
            style={{ overflow: 'visible' }}
          >
            <div>
              <DateForm
                context={this}
                date={this.state.date}
                label={'When do you need the photographer?'}
              />
              <AddressForm
                context={this}
                address={this.state.address}
                label={'Where is the shoot taking place?'}
              />
              {AdditionalDetails(this)}
              <AddonsForm
                context={this}
                addonsList ={this.props.booking.shoot.package.addons}
                addons={this.state.addons}
                date={this.state.date}
                label={'Extras, Customisation & Add-ons:'}
              />
              <form>
                <Row collapsed>
                  <Col xs={10} xsOffset={1} md={4} mdOffset={4}>
                    <Button
                      className="btn--lg btn--primary box-shadow-button"
                      action="step3"
                      context={this}
                    >
                      {'Next'}
                    </Button>
                  </Col>
                </Row>
                <Row collapsed>
                  <Col className='text-center' xs={10} xsOffset={1} md={4} mdOffset={4}>
                    <ul>{errorList}</ul>
                  </Col>
                </Row>
              </form>
            </div>
          </Col>
        </Row>
      </section>
    );
  }
}

function AdditionalDetails(context: PhotographerFormSection) {
  return (
    <form>
      <Row collapsed>
        <h4 className="color--primary text-center">
          {'How should we brief your photographer?'}
          <span
            className="tooltip"
            data-tooltip-direction="left"
            onMouseEnter={() =>
              context.segmentResource.segmentTrack('Tooltip Hovered', {
                label: 'how should we brief your photographer',
              })
            }
          >
            <div className="tooltip__anchor"></div>
            <div className="tooltip__text">
              Please briefly describe your shoot here, this will help the
              photographer to best understand your needs.
            </div>
          </span>
        </h4>
        <Col xs={12} md={10} mdOffset={1}>
          <Text
            className='photog-briefing'
            field="additional_details"
            multiline
            value={
              context.state.additional_details
                ? context.state.additional_details
                : context.props.booking.shoot.additional_details
            }
            maxLength={5000}
            placeholder="Let us know the purpose, style (candid, wide angle, etc), size (30 people, 20 products, etc), and equipment needs (backdrop, etc) of your shoot."
            context={context}
          />
        </Col>
      </Row>
    </form>
  );
}
