import React from 'react';
import { Col, Row, FormControlContext } from '@perfocal/UIKit';
import Shoot from '../../models/shoot.model';
import { format } from "date-fns";

interface SummaryFormProps {
  context: FormControlContext;
  shoot: Shoot;
  label: string;
}

interface SummaryFormState {}

export class SummaryForm
  extends React.Component<SummaryFormProps, SummaryFormState>
  implements FormControlContext {
  constructor(props: SummaryFormProps) {
    super(props);
    
    const initialState: SummaryFormState = {};
    
    this.state = initialState;
  }

  onValue() {}

  onAction() {}

  render() {
    const { shoot } = this.props
    return (
      <Col xs={12} className="boxed box-shadow-wide">
        <h4 className="color--primary"><b>{this.props.label}</b></h4>
        <form>
          <Row className="summary">
            <div>
              <Col xs={12}>
                <label>Package</label>
                <span>
                  {shoot.scene.name} - {shoot.package.name} - {shoot.package.duration}
                </span>
              </Col>
            </div>
          </Row>
          <Row className="summary">
            <div>
              <Col xs={12}>
                <label>Date &amp; Time</label>
                <span>
                  { format(shoot.starts_at,'hh:mm a, dddd Do MMMM YYYY')} 
                </span>
              </Col>
            </div>
          </Row>
          <Row className="summary">
            <div>
              <Col xs={6}>
                <label>Address</label>
                <span>{shoot.address}</span>
              </Col>
            </div>
          </Row>
          <Row className="summary">
            <div>
              <Col xs={12}>
                <label>Additional Details</label>
                <span>{shoot.additional_details || ''}</span>
              </Col>
            </div>
          </Row>
          <Row className="summary">
            <div>
              <Col xs={12} md={4}>
                <label>Email Address</label>
                <span>{shoot.user.email}</span>
              </Col>
              <Col xs={6} md={4}>
                <label>Your Name</label>
                <span>{shoot.user.first_name} {shoot.user.last_name}</span>
              </Col>
              <Col xs={6} md={4}>
                <label>Phone Number</label>
                <span>{shoot.user.phone}</span>
              </Col>
            </div>
          </Row>
        </form>
        <Row collapsed className="type--fine-print">
            <Col xs={12} className="text-left">
              By placing a booking you agree to our&nbsp;
              <a href={`https://www.${process.env.DOMAIN}/policy#terms`} target="_blank">Terms of Service</a>
            </Col>
          </Row>
      </Col>
    );
  }
}
