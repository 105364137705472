import React from 'react';
import { ProgressBar, Step } from 'react-step-progress-bar';
import 'react-step-progress-bar/styles.css';
import './index.less';
import { Col, Row } from '@perfocal/UIKit';
import { Link, HistoryUnsubscribe } from '@reach/router';
import { navigate } from 'gatsby';
import { globalHistory } from '@reach/router';

interface ProgressProviderProps {
  paths: string[];
}

interface ProgressContext {
  paths: string[];
  percentage: number;
  firstVisit: boolean;
  next: Function;
  completed: {
    [key: number]: boolean;
  };
}

const initialState: ProgressContext = {
  paths: [],
  percentage: 0,
  firstVisit: true,
  next: null,
  completed: {},
};

const ProgressContext = React.createContext(initialState);

class ProgressProvider extends React.Component<
  ProgressProviderProps,
  ProgressContext
> {
  increment: number;
  index: number;
  historyUnsubscribe: HistoryUnsubscribe;

  constructor(props: ProgressProviderProps) {
    super(props);

    this.index = this.props.paths.indexOf(
      typeof window != 'undefined' ? window.location.pathname : '',
    );
    this.increment = 100 / (this.props.paths.length - 1);

    const initialState: ProgressContext = {
      paths: props.paths,
      percentage: this.index * this.increment,
      firstVisit: true,
      next: this.next.bind(this),
      completed: {},
    };

    this.state = initialState;
  }

  componentDidMount() {
    this.historyUnsubscribe = globalHistory.listen(({ action, location }) => {
      if (this.state.firstVisit) {
        this.setState({ firstVisit: false });
      }
      this.index = this.state.paths.indexOf(location.pathname);
      this.setState({ percentage: this.index * this.increment });
    });

    const completed: { [key: number]: boolean } = {};

    for (let i = 0; i < this.props.paths.length; i++) {
      if (i === 0) {
        completed[i] = true;
      } else {
        completed[i] = false;
      }
    }

    this.setState({ completed });
  }

  componentWillUnmount() {
    this.historyUnsubscribe();
  }

  next() {
    const { percentage, completed } = this.state;
    if (this.index < this.props.paths.length) {
      this.index++;
      completed[this.index] = true;
      this.setState({ percentage: percentage + this.increment });
      navigate(this.props.paths[this.index]);
    }
  }

  render() {
    const { children } = this.props;
    return (
      <ProgressContext.Provider value={this.state}>
        {children}
      </ProgressContext.Provider>
    );
  }
}

export default ProgressContext;

export { ProgressProvider };

interface StepperProps {
  paths: string[];
  percentage: number;
  completed: {
    [key: number]: boolean;
  };
}
interface StepperState {}

export class Stepper extends React.Component<StepperProps, StepperState> {
  render() {
    const { paths, percentage, completed } = this.props;
    return (
      <section
        className="unpad--bottom space--md"
        style={{ paddingTop: '3.5rem' }}
      >
        <Row>
          <Col
            xs={10}
            xsOffset={1}
            md={8}
            mdOffset={2}
            style={{ overflow: 'visible' }}
          >
            <ProgressBar percent={percentage}>
              {paths.map(path => {
                return (
                  <Step>
                    {({ accomplished, index }: any) =>
                      completed[index] ? (
                        <Link to={`${path}`}>
                          <div
                            className={`indexedStep ${
                              accomplished ? 'accomplished' : null
                            }`}
                          >
                            {index + 1}
                          </div>
                        </Link>
                      ) : (
                        <div
                          className={`indexedStep ${
                            accomplished ? 'accomplished' : null
                          }`}
                        >
                          {index + 1}
                        </div>
                      )
                    }
                  </Step>
                );
              })}
            </ProgressBar>
          </Col>
        </Row>
      </section>
    );
  }
}
