import React from 'react';
import { Col, Row, FormControlContext, CheckList } from '@perfocal/UIKit';
import { endOfDay } from 'date-fns';
import { Addon } from '../../models/addon.model';
import { SegmentResource } from '../../resources/segment.resource';

interface AddonsFormProps {
  context: FormControlContext;
  addonsList: Addon[]
  addons: Addon[];
  label: string;
  date: Date;
}

interface AddonsFormState {
  mapped_addons: { [key: string]: Addon };
  selected_addons: Addon[];
}

export class AddonsForm
  extends React.Component<AddonsFormProps, AddonsFormState>
  implements FormControlContext {
  segmentResource: SegmentResource

  constructor(props: AddonsFormProps) {
    super(props);

    const initialState: AddonsFormState = {
      mapped_addons: {},
      selected_addons: this.props.addons || [],
    };

    this.segmentResource = new SegmentResource()
    this.state = initialState;
  }

  componentDidUpdate(prevProps: AddonsFormProps) {
    if (this.props.date !== prevProps.date) {
     this.checkUrgentAddon()
    }
  }

  checkUrgentAddon() {
    const { date } = this.props;
    const today = new Date();
    const tomorrow = new Date();
    let isDateSelected = date ? true : false;
    if ( isDateSelected && endOfDay(date) <= endOfDay(tomorrow.setDate(today.getDate() + 1))) {
      this.onValue("addons", '4', true)
    } else if (this.state.selected_addons.some((addon) => addon.id == 4)) {
      this.onValue("addons", '4', true)
    }
  }

  componentDidMount() {    
    const mappedAddons: { [key: number]: Addon } = {};

    this.props.addonsList.forEach(addon => {
      mappedAddons[addon.id] = addon;
    });

    this.setState({ mapped_addons: mappedAddons });

  }

  onValue(field: string, value: any, isValid: boolean) {
    if (!isValid) {
      this.props.context.onValue(field, value, false);
    }

    switch (field) {
      case 'addons':
        this.segmentResource.segmentTrack("Addon Clicked", {addon_id: `${value}`})
       
        const selected_addons = this.state.selected_addons;

        const current_selection = selected_addons.find(
          addon => addon.id == value,
        );
        if (current_selection) {
          selected_addons.splice(selected_addons.indexOf(current_selection), 1);
        } else {
          selected_addons.push(this.state.mapped_addons[value]);
        }

        this.setState({ selected_addons: selected_addons });
        this.props.context.onValue('addons', selected_addons, true);
    }
  }

  onAction() {}

  renderUrgentAddon = () => {
    const { date } = this.props;
    const today = new Date();
    const tomorrow = new Date();
    let isDateSelected = date ? true : false;
    const addon = {
      id: 4,
      name: 'Urgent Booking Fee',
      description:
        'When you book for today or tomorrow it is considered an urgent booking.',
      price: 4900,
    };
    if (
      isDateSelected &&
      endOfDay(date) <= endOfDay(tomorrow.setDate(today.getDate() + 1))
    ) {
      this.segmentResource.segmentTrack( "Addon Clicked", {addon_id: `4`})
      return AddonServiceOption(addon, true, true, this);
    } else {
      return <span></span>;
    }
  };

  render() {
    return (
      <form>
        <Row collapsed>
          <h4 className="color--primary text-center">{this.props.label}</h4>
          <Col xsOffset={1} xs={10}>
            {
              <CheckList
                value={this.state.selected_addons.map(addon =>
                  String(addon.id),
                )}
                field="addon_ids"
                context={this}
              >
                {this.renderUrgentAddon()}
                {this.props.addonsList.map(item => {
                  if(item.id != 4) {
                    return AddonServiceOption(
                      item,
                      !!this.state.selected_addons.find(
                        addon => addon.id == item.id,
                      ),
                      false,
                      this,
                    );
                  } else {
                    return <span></span>;
                  }
                })}
              </CheckList>
            }
          </Col>
        </Row>
      </form>
    );
  }
}

function AddonServiceOption(
  item: Addon,
  selected: boolean,
  disabled: boolean,
  context: AddonsForm,
) {
  return (
    <Col
      xs={12}
      sm={6}
      style={{ paddingTop: 0, marginBottom: '20px' }}
      key={item.id}
    >
      <div
        className={[
          'input-checkbox',
          selected ? 'addon-selected' : '',
          disabled ? 'disabled-checkbox' : '',
        ].join(' ')}
      >
        <span className="input__label">
          {item.name}&nbsp;
          <b>
            <span className="color--primary">&pound;{item.price / 100}</span>
          </b>
          &nbsp;
          <span className="tooltip" data-tooltip-direction="top">
            <div className="tooltip__anchor" data-tooltip-direction="top"></div>
            <div className="tooltip__text">{item.description}</div>
          </span>
        </span>
        <input
          id={`addons_${item.id}`}
          type="checkbox"
          name={`addons_${item.id}`}
          checked={selected}
          value={item.id}
          onChange={() => context.onValue('addons', item.id, true)}
          disabled={disabled}
        />
        <label htmlFor={`addons_${item.id}`}></label>
      </div>
    </Col>
  );
}
