import React from 'react';
import {
  Col,
  Row,
  FormControlContext,
  SelectOption,
  Select,
  DateInput,
} from '@perfocal/UIKit';
import {
  format,
  isAfter,
  isBefore,
  addHours,
  addMonths,
  endOfDay,
} from 'date-fns';
import { range } from 'lodash';

interface DateFormProps {
  context: FormControlContext;
  date?: Date;
  label: string;
}

interface DateFormState {
  date: Date;
  hour: number;
  minute: number;
}

export class DateForm extends React.Component<DateFormProps, DateFormState>
  implements FormControlContext {
  constructor(props: DateFormProps) {
    super(props);

    const initialState: DateFormState = props.date ?
    {
      date: props.date,
      hour: props.date.getHours(),
      minute: props.date.getMinutes()
    } :
    {
      date: null,
      hour: null,
      minute: null,
    };

    this.state = initialState;
  }

  onValue(field: string, value: any, isValid: boolean) {
    if (!isValid) {
      this.props.context.onValue(field, value, false);
    }

    //TODO SET VALUE OF DATE AND REFACTOR LOGIC ON RENDER

    switch (field) {
      case 'date':
      case 'hour':
      case 'minute':
        this.setState({ [field]: value } as DateFormState);
        this.props.context.onValue(field, value, true);
    }
  }

  onAction() {}

  render() {
    const { date, hour, minute } = this.state;

    //SET TIME LOGIC
    let newHour;
    let newMinute;
    if (!!hour) {
      if (hour > 12) {
        newHour = (hour - 12).toString() + ' PM';
      } else if (hour === 12) {
        newHour = hour.toString() + ' PM';
      } else {
        newHour = hour.toString() + ' AM';
      }
    }
    if (!!minute) {
      newMinute = minute.toString();
    }

    //URGENT BOOKING LOGIC
    const today = new Date();
    const tomorrow = new Date();
    let isDateSelected = date ? true : false;
    let isUrgentBooking = false;
    let startRange = 6;
    if (
      isDateSelected &&
      endOfDay(date) <= endOfDay(tomorrow.setDate(today.getDate() + 1))
    ) {
      isUrgentBooking = true;
      if (date.getDay() === today.getDay()) {
        startRange = today.getHours() + 4;
        startRange > 23 ? (startRange = 24) : startRange;
      }
    } else {
      isUrgentBooking = false;
    }

    return (
      <form>
        <Row collapsed>
          <h4 className="color--primary text-center">
            {this.props.label}
            <span className="tooltip" data-tooltip-direction="left">
              <div className="tooltip__anchor"></div>
              <div className="tooltip__text">
                Book and shoot tomorrow, or up to a year into the future.
              </div>
            </span>
          </h4>
          <Col xs={12} md={4} mdOffset={1}>
            <DateInput
              field="date"
              label=""
              value={date}
              validateFn={date =>
                isAfter(endOfDay(date), addHours(new Date(), 4)) &&
                isBefore(date, addMonths(new Date(), 12))
              }
              context={this}
            />
          </Col>
          <Col xs={6} sm={6} md={3}>
            <Select
              required
              className={newHour ? '' : 'select-opacity'}
              field="hour"
              placeholder="Hour"
              value={newHour}
              context={this}
            >
              {range(startRange, 24).map(hour => {
                const date = new Date();
                date.setHours(hour);
                const hourString = format(date, 'h A');
                return <SelectOption value={hourString} key={hour} />;
              })}
            </Select>
          </Col>
          <Col xs={6} sm={6} md={3}>
            <Select
              field="minute"
              className={newMinute ? '' : 'select-opacity'}
              placeholder="Minute"
              value={newMinute}
              required
              context={this}
            >
              <SelectOption value="00" />
              <SelectOption value="15" />
              <SelectOption value="30" />
              <SelectOption value="45" />
            </Select>
          </Col>
        </Row>
        <div className='type--fine-print text-center' >
          <p>Don't worry, <strong>you can change this later.</strong></p>
        </div>
        <Row>
          {isUrgentBooking ? (
            <Col className="text-center" style={{padding: '0 15px'}}>
              <h5 className="color--error">
                {
                  'You selected an urgent booking date and might incur an extra fee.'
                }
                <span className="tooltip" data-tooltip-direction="left">
                  <div className="tooltip__anchor"></div>
                  <div className="tooltip__text">
                    We require a minimum of 4hrs notice. If booking within end
                    of day tomorrow, an urgency fee may be incurred.
                  </div>
                </span>
              </h5>
            </Col>
          ) : null}
        </Row>
      </form>
    );
  }
}
