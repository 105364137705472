import React from 'react';
import { Col, Modal, Header, Row } from '@perfocal/UIKit';
import { SegmentResource } from '../../resources/segment.resource';

interface FaqProps {}

interface FaqState {
  displayOne: boolean;
  displayTwo: boolean;
}

export class FAQ extends React.Component <FaqProps, FaqState> {
  segmentResource:SegmentResource

  constructor(props: FaqProps) {
    super(props);
    this.state = {
      displayOne: false,
      displayTwo: false,
    };

    this.segmentResource = new SegmentResource()
  }

  openFaq(display: string) {
    if (display === 'one') {
      this.segmentResource.segmentTrack("FAQ Clicked",{ question: 'When will I meet my Perfocal Photographer?' })
      this.setState({ displayOne: !this.state.displayOne });
    } else if (display === 'two') {
      this.segmentResource.segmentTrack("FAQ Clicked",{ question: ' Can I change my booking post payment?'})
      this.setState({ displayTwo: !this.state.displayTwo });
    }
  }

  render() {
    return (
      <Col xs={12} className="boxed box-shadow-wide">
        <h4 className="color--primary">Frequently Asked Questions</h4>
        <hr className="faq-hr" />
        <div onClick={() => this.openFaq('one')} className="click" style={{paddingBottom: '.5rem'}}>
          <h5 className="faq-h5">
            When will I meet my Perfocal Photographer?
            <i className={`faq-arrow ${this.state.displayOne ? 'up' : 'down'}`} />
          </h5>
          <span className={this.state.displayOne ? '' : 'hide-one'}>
            Once your payment goes through, we will introduce you to your
            Perfocal photographer in <strong>approximately 30 minutes.</strong> Afterwards your
            photographer will contact you to better understand your
            requirements.
            <br/>
            Unsure? Give us a call 📞 020 8089 8087
          </span>
        </div>
        <hr className="faq-hr" />
        <div onClick={() => this.openFaq('two')} className="click" style={{paddingBottom: '.5rem'}}>
          <h5 className="faq-h5">
            Can I change my booking post payment?
            <i className={`faq-arrow ${this.state.displayTwo ? 'up' : 'down'}`} />
          </h5>
          <span className={this.state.displayTwo ? '' : 'hide-two'}>
            <strong>Of course! </strong>We pride ourselves at being flexible. You can change the
            time, the date, the location and even your photographer for free as
            long as it’s 24 hours before your shoot! 
            <br/>
            Unsure? Give us a call 📞 020 8089 8087
          </span>
        </div>
        <hr className="faq-hr" />
        <Row collapsed className="type--fine-print">
            <Col xs={12} className="text-left" style={{paddingTop:'10px'}}>
              You need more answers?&nbsp;
              <a href={`https://www.${process.env.DOMAIN}/info/#faq`} target="_blank">FAQ</a>
            </Col>
          </Row>
      </Col>
    );
  }
}
