import React from 'react';
import { BookingFormContext } from '../../context/booking-form';
import { RouteComponentProps } from '@reach/router';
import { Button, Col, Row, FormControlContext } from '@perfocal/UIKit';
import { SceneForm } from './scene-form';
import { ContactForm } from './contact-form';
import { Scene } from '../../models/scene.model';
import { Package } from '../../models/package.model';
import { PackageForm } from './package-form';
import { SceneResource } from '../../resources/scene.resource';
import ProgressContext from '../../context/ProgressContext';
import { SegmentResource } from '../../resources/segment.resource';
import queryString from 'query-string';
import { ShootResource } from '../../resources/shoot.resource';
import { ZapierResource } from '../../resources/zapier.resource';
import { CustomerIoResource } from '../../resources/customerio.resource';
import { isEmpty } from 'lodash';

interface UserFormSectionProps extends RouteComponentProps {
  children?: React.ReactNode;
  progress: ProgressContext;
  booking: BookingFormContext;
}

interface UserFormSectionState {
  scene_list: Scene[];
  package_list: Package[];
  email: string;
  phone: string;
  first_name: string;
  last_name: string;
  scene: Scene;
  package: Package;
  showErrors: boolean;
  errorMessages: string[]
}

export class UserFormSection
  extends React.Component<UserFormSectionProps, UserFormSectionState>
  implements FormControlContext {
  sceneResource: SceneResource;
  shootResource: ShootResource;
  segmentResource: SegmentResource;
  queryParams: queryString.ParsedQuery<string>;
  zapierResource: ZapierResource;
  customerIoResource: CustomerIoResource

  constructor(props: UserFormSectionProps) {
    super(props);

    const initialState: UserFormSectionState = {
      scene_list: props.booking.scene_list || [],
      package_list: [],
      email: props.booking.shoot.user.email || null,
      phone: props.booking.shoot.user.phone || null,
      first_name: props.booking.shoot.user.first_name || null,
      last_name: props.booking.shoot.user.last_name || null,
      scene: props.booking.shoot.scene || ({} as Scene),
      package: props.booking.shoot.package || ({} as Package),
      showErrors: false,
      errorMessages: null
    };

    this.queryParams = queryString.parse(this.props.location.search);

    this.sceneResource = new SceneResource();
    this.segmentResource = new SegmentResource();
    this.shootResource = new ShootResource();
    this.zapierResource = new ZapierResource();
    this.customerIoResource = new CustomerIoResource();
    this.state = initialState;
  }

  async componentDidMount() {
    this.props.booking.turnOffLoader();

    this.segmentResource.segmentLoad();

    //TODO shoot_id params inferr whole shoot -- ?shoot_id=NLX7JMEA
    // if(queryParams.shoot_id){
    //   // const shoot = await this.shootResource.getUnpaidShoot(queryParams.shoot_id.toString())
    // }

    //Set referral code globally -- ?promocode=REF-6728
    if (this.queryParams.promocode) {
      this.props.booking.promocode = this.queryParams.promocode.toString();
    }

    let scene_list: Scene[] = await this.sceneResource.getAll();
    let package_list: Package[] = [];

    //Prefill scene from params -- ?scene_id=4&package_id=40
    if (!!this.queryParams.scene_id) {
      scene_list.map(scene => {
        if (scene.id.toString() === this.queryParams.scene_id) {
          this.props.booking.shoot.scene = scene;
          this.setState({ scene });
        }
      });
    }

    if (this.props.booking.shoot.scene.id) {
      package_list = await this.sceneResource.scenePackages(
        this.props.booking.shoot.scene.id,
      );
    }
    this.setState({ scene_list: scene_list, package_list: package_list });
    this.props.booking.scene_list = scene_list

    //Prefill package from params -- ?scene_id=4&package_id=40
    if (!!this.queryParams.package_id) {
      package_list.map(pkg => {
        if (pkg.id.toString() === this.queryParams.package_id) {
          this.props.booking.shoot.package = pkg;
          this.setState({ package: pkg });
        }
      });
    }

    this.segmentResource.segmentTrack('Viewed Checkout Step', { step: 1 })

  }

  checkForm(): boolean {
    const { email, phone, first_name, last_name, scene } = this.state;
    let errorMessages = []
    !scene.id ? errorMessages.push('Please select the type of shoot') : null
    !this.state.package.id ? errorMessages.push('Please select a package') : null
    email == null || phone == null || first_name == null || last_name == null ? errorMessages.push('Please fill in your contact details') : null
    this.setState({errorMessages})
    return (
      email != null &&
      phone != null &&
      first_name != null &&
      last_name != null &&
      !!scene.id &&
      !!this.state.package.id
    );
  }

  async onValue(field: string, value: any, isValid: boolean) {
    if (isValid) {
      switch (field) {
        case 'purpose':
          this.setState({ scene: {} as Scene, package: {} as Package });
          this.props.booking.purpose = value;
          break;
        case 'scene':
          const package_list: Package[] = await this.sceneResource.scenePackages(
            value.id,
          );
          this.setState({
            package: {} as Package,
            package_list: package_list,
            scene: value,
          });
          break;
        case 'package':
          this.props.booking.shoot.scene = this.state.scene;
          this.props.booking.shoot.package = value;
          this.setState({ package: value });
          break;
        case 'email':
        case 'first_name':
        case 'last_name':
        case 'phone':
          this.props.booking.shoot.user[field] = value;
          this.setState({ [field]: value } as UserFormSectionState);
      }
      this.state.showErrors ? this.checkForm() : null
    }
  }

  onAction(action: string) {
    switch (action) {
      case 'step2':
        if(this.checkForm()) {        
          this.segmentResource.segmentTrack('Completed Checkout Step', { 
            step: 1,  
            scene_id: `${this.props.booking.shoot.scene.id}`,
            package_id: `${this.props.booking.shoot.package.id}`,
          })
  
          this.segmentResource.segmentIdentify(
            this.props.booking.shoot.user.first_name,
            this.props.booking.shoot.user.email,
          );

          const context_shoot = this.props.booking.shoot
          this.zapierResource.zapierPost({
            first_name: context_shoot.user.first_name,
            last_name: context_shoot.user.last_name,
            email: context_shoot.user.email,
            phone: context_shoot.user.phone,
            package_name: context_shoot.package.name,
            scene_name: context_shoot.scene.name,
            package_id: context_shoot.package.id,
            scene_id: context_shoot.scene.id,
            purpose: this.props.booking.purpose,
            admin_url:`https://admin.${process.env.DOMAIN}/orders/${context_shoot.id}`,
            time_stamp: new Date()
          })
  
          this.customerIoResource.newLead({
            first_name: context_shoot.user.first_name,
            last_name: context_shoot.user.last_name,
            email: context_shoot.user.email,
            phone: context_shoot.user.phone,
            scene_name: context_shoot.scene.name,
            package_name: context_shoot.package.name,
            scene_id: context_shoot.scene.id.toString(),
            package_id: context_shoot.package.id.toString(),
            purpose: context_shoot.scene.purpose
          })

          this.props.progress.next();
        } else {
          this.setState({showErrors: true})
        }
    }
  }

  render() {

    let errorList;
    if (this.state.showErrors) {
      errorList = this.state.errorMessages.map((error, key) => {
        return <li key={key}><span className="color--error">{error}</span></li>;
      })
    }

    return (
      <section className="unpad--bottom" style={{paddingTop:'3.5rem'}}>
        <Row>
          <Col
            xs={12}
            md={8}
            mdOffset={2}
            className="boxed box-shadow-wide"
            style={{ overflow: 'visible' }}
          >
            <div>
              <SceneForm
                context={this}
                scene={this.state.scene}
                label={'Firstly, what are you hiring a photographer for?'}
                scene_list={this.state.scene_list}
                purpose={
                  this.queryParams.purpose
                    ? (((this.queryParams.purpose as string)
                        .charAt(0)
                        .toUpperCase() +
                        this.queryParams.purpose.slice(1)) as string)
                    : null
                }
              />
              {this.state.scene.id ? (
                <PackageForm
                  context={this}
                  package={this.state.package}
                  label={'Brilliant! Pick your package:'}
                  packages_list={this.state.package_list}
                />
              ) : null}
              {this.state.scene.id && !isEmpty(this.state.package) ? 
                <ContactForm
                  context={this}
                  user={{
                    first_name: this.state.first_name,
                    last_name: this.state.last_name,
                    email: this.state.email,
                    phone: this.state.phone,
                  }}
                  label={'Okay, how can we contact you?'}
                />
                : null}
              <form>
                <Row collapsed>
                  <Col xs={10} xsOffset={1} md={4} mdOffset={4}>
                    <Button
                      className="btn--lg btn--primary box-shadow-button"
                      action="step2"
                      context={this}
                    >
                      {'Next'}
                    </Button>
                  </Col>
                </Row>
                <Row collapsed>
                  <Col className='text-center' xs={10} xsOffset={1} md={4} mdOffset={4}>
                    <ul>{errorList}</ul>
                  </Col>
                </Row>
              </form>
            </div>
          </Col>
        </Row>
      </section>
    );
  }
}
