import React from 'react';
import { Router } from '@reach/router';
import { Layout } from '../components/Layout';
import { Seo } from '../components/Seo';
import { UserFormSection } from '../components/UserFormSection';
import { PhotographerFormSection } from '../components/PhotographerFormSection';
import { PaymentFormSection } from '../components/PaymentFormSection';
import ProgressContext, {
  ProgressProvider,
  Stepper,
} from '../context/ProgressContext';
import BookingFormContext from '../context/booking-form';

function Step() {
  return (
    <BookingFormContext.Consumer>
      {booking => (
        <Layout booking={booking}>
          {process.env.DOMAIN == 'perfocal.dev'?
              <div style={{position: 'fixed', float: 'right', top: '1rem', right: '1rem', fontWeight: 900, zIndex: 1000, color: 'rgb(83, 90, 224)'}}>
                TEST MODE
              </div>
            : null }
          <Seo title="Home" />
          <ProgressProvider paths={['/step/1', '/step/2', '/step/summary']}>
            <ProgressContext.Consumer>
              {progress => (
                <>
                  {!progress.firstVisit ? (
                    <Stepper
                      paths={progress.paths}
                      percentage={progress.percentage}
                      completed={progress.completed}
                    />
                  ) : null}
                  <Router>
                    <UserFormSection
                      path="/step/1"
                      progress={progress}
                      booking={booking}
                    />
                    <PhotographerFormSection
                      path="/step/2"
                      progress={progress}
                      booking={booking}
                    />
                    <PaymentFormSection
                      path="/step/summary"
                      booking={booking}
                    />
                    <UserFormSection
                      path="/"
                      progress={progress}
                      booking={booking}
                    />
                  </Router>
                </>
              )}
            </ProgressContext.Consumer>
          </ProgressProvider>
        </Layout>
      )}
    </BookingFormContext.Consumer>
  );
}

export default Step;
