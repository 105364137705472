import * as request from "superagent";
import Resource from "./_base.resource";
import { Package } from "../models/package.model";
import { Scene } from "../models/scene.model";


export class SceneResource extends Resource {
  constructor() {
    super("/scenes");
  }

  async getAll(): Promise<Scene[]> {
    const req = request
      .get(`${this._baseURI}`)
      .set("Accept", "application/json")
      .set("Content-type", "application/json");

    const response = await req.send();
    return response.body;
  }

  async scenePackages(id: number): Promise<Package[]> {
    const req = request
      .get(`${this._baseURI}/${id}/packages`)
      .set("Accept", "application/json")
      .set("Content-type", "application/json");

    const response = await req.send();
    return response.body;
  }

}



