import React from 'react';
import { Col, Row, FormControlContext, Text } from '@perfocal/UIKit';
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import User from '../../models/user.model';

interface ContactFormProps {
  context: FormControlContext;
  label: string;
  user: User;
};

interface ContactFormState {
  email: string;
  phone: string;
  first_name: string;
  last_name: string;
};

export class ContactForm extends React.Component<ContactFormProps, ContactFormState> implements FormControlContext {

  constructor(props: ContactFormProps) {
    super(props);

    const initialState: ContactFormState = {
      email: this.props.user.email || null,
      phone: this.props.user.phone || null,
      first_name: this.props.user.first_name || null,
      last_name: this.props.user.last_name || null,
    }
    
    this.state = initialState;
  }

  onValue(field: string, value: any, isValid: boolean) {
    if(!isValid){
      this.props.context.onValue(field, value, false);
    }

    switch(field) {
      case "email":
      case "first_name":
      case "last_name":
        this.setState({[field]: value} as ContactFormState)
        this.props.context.onValue(field, value, true);
    }
  }

  onAction() {
  }

  render() {
    const emailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return (
      <form>
        <Row collapsed>
          <h4 className="color--primary text-center">
            {this.props.label}
          </h4>
          <div className='type--fine-print text-center' style={{marginBottom:'.85rem'}}>
            <p>We'll send your quote to this inbox. Perfocal <strong>never spams</strong>.</p>
          </div>
          <Col xs={6} md={3} lg={3}>
            <Text
              required
              field="first_name"
              value={this.state.first_name}
              placeholder="First Name"
              context={this}
            />
          </Col>
          <Col xs={6} md={3} lg={3}>
            <Text
              required
              field="last_name"
              value={this.state.last_name}
              placeholder="Last Name"
              context={this}
            />
          </Col>
          <Col xs={12} md={3} lg={3}>
             <PhoneInput
              required
              international
              defaultCountry='GB'
              value={this.state.phone? '+' + this.state.phone : null }
              maxLength={16}
              onChange={(value: string) => {
                const phone = value ? value.replace('+', '') : value
                this.setState({phone: phone})
                this.props.context.onValue('phone', phone, true);
              }}
            />
          </Col>
          <Col xs={12} md={3} lg={3}>
            <Text
              required 
              field="email"
              placeholder="Email Address"
              value={this.state.email}
              validateFn={value => emailRegEx.test(value)}
              context={this}
            />
          </Col>
        </Row>
        <div className='type--fine-print text-center' >
          <p>We need this so your <strong>local photographer</strong> can contact you.</p>
        </div>
      </form>
    );
  }
}

