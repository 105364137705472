import React from 'react';
import { BookingFormContext } from '../../context/booking-form';
import { RouteComponentProps, navigate } from '@reach/router';
import { FormControlContext, Row, Col} from '@perfocal/UIKit';
import { SummaryForm } from './summary-form';
import { FAQ } from './faq';
import './index.less';
import { CheckoutForm } from './checkout-form';
import { PaymentForm } from './payment-form';
import { Payment } from '../../models/payment.model';
import { ShootResource } from '../../resources/shoot.resource';
import { CreateShootDto } from '../../resources/dto/shoot/create-shoot.dto';
import { SegmentResource } from '../../resources/segment.resource';
import { isMobile } from "react-device-detect";
import { ZapierResource } from '../../resources/zapier.resource';
import moment from 'moment';

interface PaymentFormSectionProps extends RouteComponentProps {
  children?: React.ReactNode;
  booking: BookingFormContext;
}

interface PaymentFormSectionState {
  payment: Payment;
}

export class PaymentFormSection extends React.Component<PaymentFormSectionProps, PaymentFormSectionState> implements FormControlContext {
  shootResource: ShootResource
  segmentResource: SegmentResource
  zapierResource: ZapierResource

  constructor(props: PaymentFormSectionProps) {
    super(props);

    const initialState: PaymentFormSectionState = {
      payment: {
        total_amount: props.booking.shoot.package.price + props.booking.shoot.package.addons.reduce((n, item) => n + item.price, 0),
      }
    };

    this.segmentResource = new SegmentResource();
    this.shootResource = new ShootResource();
    this.zapierResource = new ZapierResource();

    this.state = initialState;
  }

  async componentDidMount() {
    this.props.booking.turnOffLoader();

    this.segmentResource.segmentLoad();

    const {booking} = this.props
    let payload: CreateShootDto = {
      id: booking.shoot.id,
      address: booking.shoot.address,
      city: booking.shoot.city || 'No City',
      email: booking.shoot.user.email,
      first_name: booking.shoot.user.first_name,
      last_name: booking.shoot.user.last_name,
      phone: booking.shoot.user.phone,
      latitude: String(booking.shoot.latitude),
      longitude: String(booking.shoot.longitude),
      package_id: String(booking.shoot.package.id),
      postcode: booking.shoot.postcode,
      starts_at: moment(booking.shoot.starts_at).format('YYYY-MM-DDTHH:mm:ss'),
      additional_details: booking.shoot.additional_details,
      payment_id: booking.shoot.payment.id ? String(booking.shoot.payment.id) : null,
      payment_intent_id: booking.shoot.payment.stripe_payment_intent ? String(booking.shoot.payment.stripe_payment_intent) : null,
      addon_ids: booking.shoot.addons.map(addon => String(addon.id))
    }

    const shoot = await this.shootResource.create(payload)
    booking.shoot.id = shoot.id

    this.setState({payment:shoot['payments'][0]})

     //Send data to Zapier
    // const context_shoot = this.props.booking.shoot
    // this.zapierResource.zapierPost({
    //   first_name: context_shoot.user.first_name,
    //   last_name: context_shoot.user.last_name,
    //   email: context_shoot.user.email,
    //   phone: context_shoot.user.phone,
    //   package_name: context_shoot.package.name,
    //   scene_name: context_shoot.scene.name,
    //   package_id: context_shoot.package.id,
    //   scene_id: context_shoot.scene.id,
    //   purpose: this.props.booking.purpose,
    //   admin_url:`https://admin.perfocal.com/orders/${context_shoot.id}`,
    //   time_stamp: new Date()
    // })

    this.segmentResource.segmentTrack('Viewed Checkout Step', { step: 3 })
     
  }

  onValue(field: string, value: any, isValid: boolean) {
    switch(field) {
      case "payment":  
        this.setState({payment: Object.assign(JSON.parse(JSON.stringify(this.state.payment)), value)})
    }
  }

  async onAction(action: string) { 

    switch(action) {
      case "payment-free":
        const {booking} = this.props

        try {
          await this.shootResource.freeCheckout(
            booking.shoot.id,
            this.state.payment['promocode']['name'],
            this.state.payment.stripe_payment_intent, 
            this.state.payment.stripe_payment_intent_secret,
          )
  
          navigate(`/thank-you`) 
    
        } catch (error) {
          alert('Something went wrong, please try again or contact us.')
        }
      break;
      case "payment-success":
        let addonObject: any = {};
        this.props.booking.shoot.package.addons.forEach(addon => {
          let addon_id = `addon_${addon.id}`;
          addonObject[addon_id] = false;
          if(this.props.booking.shoot.addons && this.props.booking.shoot.addons.includes(addon)){
            addonObject[addon_id] = true;
          }
          return addonObject;
        })

        this.segmentResource.segmentTrack("Order Completed", {
          orderId: this.props.booking.shoot.id,
          products: [
            {
              id: this.props.booking.shoot.package.id,
              name: `${this.props.booking.shoot.scene.name} ${this.props.booking.shoot.package.name} ${this.props.booking.shoot.package.duration}`,
              addons: addonObject,
              price: this.props.booking.shoot.package.price / 100,
              total: this.state.payment.total_amount / 100,
              currency: 'GBP',
              quantity: 1
            }
          ],
        })

        this.segmentResource.segmentTrack('Completed Checkout Step', { step: 3 })

        navigate(`/thank-you`) 
      break;
    }
  }

  render() {
    return (
      <section className="space--md fadein unpad--bottom"  style={{ paddingTop: '3.5rem' }}>
        <Row style={{paddingLeft: '15px', paddingRight: '15px'}}>
          <Col xs={12} md={8} mdOffset={2}>
            <Row>
              <Col xs={12} md={7}>
                <Row collapsed>
                  <SummaryForm context={this} shoot={this.props.booking.shoot} label={'Booking Summary'} />
                  <FAQ />
                </Row>
              </Col>
              <Col xs={12} md={5}>
                <div className={`${!isMobile? 'boxed box-shadow-wide' : '' }`}>
                  <h4 className="color--primary"><b>Payment</b></h4>
                  <CheckoutForm promocode={this.props.booking.promocode} package={this.props.booking.shoot.package} scene={this.props.booking.shoot.scene} addons={this.props.booking.shoot.addons} context={this} payment_intent_id={this.state.payment.stripe_payment_intent}/>
                  <PaymentForm field={'payment'} context={this} payment={this.state.payment} />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>
    );
  }
}
