import * as request from "superagent";
import Resource from "./_base.resource";
import { ApplyPromocodeDto } from "./dto/promocode/apply-promocode.dto";
import { Payment } from "../models/payment.model";


export class PromocodeResource extends Resource {
  constructor() {
    super("/promocodes");
  }

  async applyPromocode(promocode: ApplyPromocodeDto): Promise<Payment> {

    const req = request
    .post(this._baseURI + '/apply')
    .set("Accept", "application/json")
    .set("Content-type", "application/json");

    const response = await req.send(promocode);

    return response.body;
  }

}