import React from 'react';
import { Col, Row, FormControlContext, Map } from '@perfocal/UIKit';
import '@perfocal/UIKit/form/form.less';
import '@perfocal/UIKit/form/address.less';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import Shoot from '../../models/shoot.model';

interface AddressFormProps {
  context: FormControlContext;
  label: string;
  address?: AddressType;
}

interface AddressFormState {
  address: AddressType;
  addressText: string;
  isValid: boolean;
}

interface AddressType {
  addressText: string;
  lat: number;
  lng: number;
  city: string;
  postcode: string;
}

export class AddressForm
  extends React.Component<AddressFormProps, AddressFormState>
  implements FormControlContext {
  constructor(props: AddressFormProps) {
    super(props);

    const initialState: AddressFormState = {
      address: props.address,
      addressText: props.address.addressText,
      isValid: false,
    };

    this.state = initialState;
    this.onChange = this.onChange.bind(this);
  }

  async onChange(address: string) {
    const geocodes = await geocodeByAddress(address);
    const { lat, lng } = await getLatLng(geocodes[0]);
    const city = geocodes[0].address_components.filter(
      (t: any) => t.types[0] === 'postal_town' || t.types[0] === 'locality',
    )[0].long_name;

    //CHANGE TO THIS TO ACCEPT ONLY SPECIFIC POSTCODES
    // const postcode = geocodes[0].address_components.filter(t => t.types[0] === "postal_code")[0].long_name;

    const postcode = geocodes[0].address_components.filter(
      (t: any) => t.types[0] === 'postal_code',
    )[0]
      ? geocodes[0].address_components.filter(
          (t: any) => t.types[0] === 'postal_code',
        )[0].long_name
      : '';

    const value: AddressType = {
      addressText: address,
      lat,
      lng,
      city,
      postcode,
    };

    await this.setState({
      addressText: address,
      address: value,
      isValid: true,
    });

    this.onValue('address', value, true);
  }

  onValue(field: string, value: any, isValid: boolean) {
    if (!isValid) {
      this.props.context.onValue(field, value, false);
    }

    switch (field) {
      case 'address':
        this.props.context.onValue(field, value, true);
    }
  }

  onAction() {}

  render() {
    const inputProps = {
      value: this.state.addressText,
      onChange: (addressText: string) => {
        this.setState({ addressText });
      },
      placeholder: 'Search for address',
    };

    const inputOptions = {
      componentRestrictions: {
        country: 'uk',
      },
    };

    let label: any = '';
    if (true) {
      let className = true ? 'required' : '';
      label = <label className={className}>{'Label'}</label>;
    }
    const { lat, lng } = this.state.address;

    const cssClasses = {
      autocompleteContainer: 'map-autocomplete-container',
      autocompleteItem: 'map-autocomplate-item',
      autocompleteItemActive: 'map-autocomplate-item-active',
    };
    const zoom = this.state.address.addressText ? 15 : 6;
    const showMarker = this.state.address.addressText ? true : false;

    return (
      <form>
        <Row collapsed>
          <h4 style={{marginBottom:0}} className="color--primary text-center">
            {this.props.label || 'Where is the shoot taking place?'}
            <span className="tooltip" data-tooltip-direction="left">
              <div className="tooltip__anchor"></div>
              <div className="tooltip__text">
                {'We introduce you to a photographer nearby to your chosen location.'}
              </div>
            </span>
          </h4>
          <div className='type--fine-print text-center' style={{margin: '.5rem 0rem', paddingLeft:'15px'}}>
            {this.props.address.addressText ? <span style={{fontSize:'1rem'}} className="color--success">Nice, your shoot location is covered by Perfocal!</span>: null}
          </div>
          <Col xs={12} md={10} mdOffset={1}>
            <div className="input">
              <PlacesAutocomplete
                inputProps={inputProps}
                classNames={cssClasses}
                onEnterKeyDown={this.onChange}
                onSelect={this.onChange}
                options={inputOptions}
              />
              <Map zoom={zoom} showMarker={showMarker} center={{ lat, lng }} />
            </div>
          </Col>
        </Row>
        <div className='type--fine-print text-center' >
          <p>We need this to send you your <strong>perfect local photographer.</strong></p>
        </div>
      </form>
    );
  }
}
