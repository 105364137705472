import * as request from "superagent";
import Resource from "./_base.resource";
import Shoot from "../models/shoot.model";
import { CreateShootDto } from "./dto/shoot/create-shoot.dto";
import { NewLeadDto } from "./dto/cutomer-io/new-lead.dto";


export class CustomerIoResource extends Resource {
  constructor() {
    super("/customer-io");
  }

  async newLead(payload: NewLeadDto): Promise<any> {
    const req = request
    .post(`${this._baseURI}/lead`)
    .set("Accept", "application/json")
    .set("Content-type", "application/json");

    const response = await req.send(payload);
    return response.body;
  }



}