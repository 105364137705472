import * as React from 'react';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  injectStripe,
  ReactStripeElements,
  StripeProvider,
  Elements,
} from 'react-stripe-elements';
import { Row, Col, Button, FormControlContext} from '@perfocal/UIKit';
import '@perfocal/UIKit/form/payment.less';
import { Payment } from '../../models/payment.model';
import { isEqual } from 'lodash';
import { SegmentResource } from '../../resources/segment.resource';
import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import { isMobile } from "react-device-detect";
import { navigate } from 'gatsby';
import { ShootResource } from '../../resources/shoot.resource';

interface PaymentFormProcessorProps {
  field: string;
  context: FormControlContext;
  payment: Payment;
  stripe?: ReactStripeElements.StripeProps;
}

interface PaymentFormProcessorState {
  amount: number;
  card_number?: boolean;
  expiry?: boolean;
  cvc?: boolean;
  processing: boolean;
}

class PaymentFormProcessor
  extends React.Component<PaymentFormProcessorProps, PaymentFormProcessorState>
  implements FormControlContext {
  segmentResource: SegmentResource
  MINIMUM_VALUE = 30
  shootResource: ShootResource

  constructor(props: PaymentFormProcessorProps) {
    super(props);
    const initialState: PaymentFormProcessorState = {
      amount: props.payment.total_amount - (props.payment.discount || 0),
      card_number: false,
      expiry: false,
      cvc: false,
      processing: null,
    };

    this.state = initialState;
    this.onValue = this.onValue.bind(this);
    this.segmentResource = new SegmentResource()
  }

  async componentDidUpdate(prevProps: PaymentFormProcessorProps) {
    if (!isEqual(this.props.payment, prevProps.payment)) {
      this.setState({
        amount: this.props.payment.total_amount - (this.props.payment.discount || 0),
      });
    }
  }

  async onAction(action: string) {
    const { context, payment, field, stripe } = this.props;
    switch (action) {
      case 'submit':
        this.setState({ processing: true });
        context.onValue(`${field}-processing`, true, true);

        if(this.state.amount < this.MINIMUM_VALUE) {
         context.onAction(`${field}-free`)
        } else {
          const { error } = await stripe.handleCardPayment(
            payment.stripe_payment_intent_secret,
            {},
          );
  
          if (error) {
            this.setState({ processing: false });
            context.onValue(`${field}-processing`, false, true);
            context.onValue(`${field}-error`, true, true);
  
            alert(
              `${error.message} Please check the card information and try again.`,
            );
          } else {
            context.onAction(`${field}-success`);
  
          }
        }
        break;
      default:
    }
  }

  onValue(field: string, value: any, isValid: boolean) {
    const { context } = this.props;
    if (!isValid) {
      context.onValue(field, value, false);
    }

    switch (field) {
      case 'card_number':
      case 'expiry':
      case 'cvc':
        this.segmentResource.segmentTrack('Card Details Entered', {});
        this.setState({ [field]: value } as PaymentFormProcessorState);
    }
  }

  render() {
    const inputStyle = {
      base: {
        fontSize: '14px',
        fontFamily: "'Nunito Sans', 'Avenir', 'Helvetica', 'Arial', sans-serif",
        lineHeight: '20px',
        color: '#2a2841',
        '::placeholder': {
          color: '#a4aab3',
        },
      },
      invalid: {
        color: '#fc4e6b',
      },
    };

    const { card_number, expiry, cvc, processing } = this.state;
    const enableSubmit = (card_number && expiry && cvc && !processing) || this.state.amount < this.MINIMUM_VALUE;

    return (
      <form>
        <Col xs={12} >
          {this.state.amount > this.MINIMUM_VALUE ?
          <>
          <Row collapsed>
            <Col xs={12}>
              <CardNumberElement
                onChange={value => this.onValue('card_number', value.complete, true)}
                style={inputStyle}
                disabled={this.state.amount <= 0}
              />
            </Col>
          </Row>
          <Row collapsed>
            <Col xs={6}>
              <CardExpiryElement
                onChange={value => this.onValue('expiry', value.complete, true)}
                style={inputStyle}
                disabled={this.state.amount <= 0}
              />
            </Col>
            <Col xs={6}>
              <CardCVCElement
                onChange={value => this.onValue('cvc', value.complete, true)}
                style={inputStyle}
                disabled={this.state.amount <= 0}
              />
            </Col>
          </Row>
          {paymentImg()}
          </>
          :
            <span/>
          }
          <Row collapsed>
            <Col xs={12} md={6} mdOffset={3}>
              <Button
                className="btn--primary box-shadow-button"
                disabled={!enableSubmit}
                action="submit"
                context={this}
              >
                {this.state.processing ?
                <Loader type="ThreeDots" height={15} color="white"/>
                :
                this.state.amount > this.MINIMUM_VALUE
                  ? `Pay £${this.state.amount / 100}`
                  : 'Process'
                }
              </Button>
            </Col>
          </Row>
        </Col>
        <div className="type--fine-print text-center">
          <p>You can cancel up to 24 hours before your shoot</p>
        </div>
        {!isMobile ? trustpilotImg() : <span></span>}
      </form>
    );
  }
}

const PaymentFormConsumer = injectStripe(PaymentFormProcessor);
const stripeKey = process.env.STRIPE_API_PUBLIC;

interface PaymentFormState {
  stripe: any;
}

export class PaymentForm extends React.Component<
  PaymentFormProcessorProps,
  PaymentFormState
> {
  constructor(props: PaymentFormProcessorProps) {
    super(props);
    this.state = {
      stripe: null,
    };
  }

  componentDidMount() {
    if (window.Stripe) {
      this.setState({ stripe: window.Stripe(stripeKey) });
    } else {
      document.querySelector('#stripe-js').addEventListener('load', () => {
        this.setState({ stripe: window.Stripe(stripeKey) });
      });
    }
  }

  async componentDidUpdate(prevProps: PaymentFormProcessorProps) {
    if (!isEqual(this.props, prevProps)) {
      this.render();
    }
  }

  render() {
    //TODO HANDLE ENV VARIABLES
    return (
      <div>
        <StripeProvider stripe={this.state.stripe}>
          <Elements>
            <PaymentFormConsumer
              payment={this.props.payment}
              field={this.props.field}
              context={this.props.context}
            />
          </Elements>
        </StripeProvider>
      </div>
    );
  }
}

function trustpilotImg() {
  return (
    <Row style={{ padding: '5px 0' }}>
      <p style={{ textAlign: 'center' }}>
        <a
          target='_blank'
          href="https://uk.trustpilot.com/review/perfocal.com"
          style={{ textDecoration: 'none' }}
        >
          <img
            src="https://emailsignature.trustpilot.com/newsletter/en-GB/1/598b39330000ff0005a87f3c/text1@2x.png"
            height={18}
            alt="Human score"
            style={{ maxHeight: '12px' }}
          />
        </a>{' '}
        &nbsp;&nbsp;
        <a
          target='_blank'
          href="https://uk.trustpilot.com/review/perfocal.com"
          style={{ textDecoration: 'none' }}
        >
          <img
            src="https://emailsignature.trustpilot.com/newsletter/en-GB/1/598b39330000ff0005a87f3c/stars@2x.png"
            height={20}
            alt="Trustpilot Stars"
            style={{ maxHeight: '14px' }}
          />
        </a>{' '}
        &nbsp;&nbsp;
        <span style={{ display: 'inline-block' }}>
          <a
            target='_blank'
            href="https://uk.trustpilot.com/review/perfocal.com"
            style={{ textDecoration: 'none' }}
          >
            <img
              src="https://emailsignature.trustpilot.com/newsletter/en-GB/1/598b39330000ff0005a87f3c/text2@2x.png"
              height={18}
              alt="number of reviews"
              style={{ maxHeight: '12px' }}
            />
          </a>{' '}
          &nbsp;&nbsp;
          <a
            target='_blank'
            href="https://uk.trustpilot.com/review/perfocal.com"
            style={{ textDecoration: 'none' }}
          >
            <img
              src="https://emailsignature.trustpilot.com/brand/n/1/logo.png"
              height={20}
              alt="Trustpilot Logo"
              style={{ maxHeight: '14px', display: 'inline-block' }}
            />
          </a>
        </span>
      </p>
    </Row>
  );
}

function paymentImg() {
  return (
    <Row collapsed>
      <Col xs={12}>
        <ul className="payment methods">
          <li>
            <img
              className="visa"
              src="https://s3-eu-west-1.amazonaws.com/perfocal-asset/images/visa.png"
            />
          </li>
          <li>
            <img
              className="mastercard"
              src="https://s3-eu-west-1.amazonaws.com/perfocal-asset/images/mastercard.svg"
            />
          </li>
          <li>
            <img
              className="amex"
              src="https://s3-eu-west-1.amazonaws.com/perfocal-asset/images/american_express.png"
            />
          </li>
          <li>
            <img
              className="stripe"
              src="https://s3-eu-west-1.amazonaws.com/perfocal-asset/images/stripe.svg"
            />
          </li>
        </ul>
      </Col>
    </Row>
  );
}
