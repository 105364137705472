import React from 'react';
import {
  Col,
  Radio,
  Row,
  FormControlContext,
  TextOption,
  RadioOption,
} from '@perfocal/UIKit';
import { Scene } from '../../models/scene.model';
import { isEqual } from 'lodash';
import { SegmentResource } from '../../resources/segment.resource';

interface SceneFormProps {
  context: FormControlContext;
  scene_list: Scene[];
  scene: Scene;
  purpose: string;
  label?: string;
};

interface SceneFormState {
  scene_dictionary: { [key: number]: Scene };
  scene_list: { [key: string]: Scene[] };
  purpose: string;
  scene: Scene;
  scenesToShow: number
};

export class SceneForm extends React.Component<SceneFormProps, SceneFormState>
  implements FormControlContext {
  PURPOSES = ['Business', 'Personal'];
  segmentResource: SegmentResource

  constructor(props: SceneFormProps) {
    super(props);

    const initialState: SceneFormState = {
      scene_dictionary: {},
      scene_list: this.props.context.props.booking.scene_list_mapped || {},
      purpose: props.scene.purpose || props.purpose || null,
      scene: props.scene || {} as Scene,
      scenesToShow: 8
    };

    this.state = initialState;

    this.segmentResource = new SegmentResource()
  }

  async componentDidMount() {
    const mapped_scenes: { [key: string]: Scene[] } = {};
    const scene_dictionary: { [key: number]: Scene } = {};

    this.props.scene_list.forEach(scene => {
      if (!mapped_scenes[scene.purpose as string]) {
        mapped_scenes[scene.purpose] = [];
      }
      mapped_scenes[scene.purpose].push(scene);
      scene_dictionary[scene.id] = scene;
    });

    this.setState({
      scene_list: mapped_scenes,
      scene_dictionary: scene_dictionary,
      purpose: this.props.scene.purpose || this.props.purpose || null,
      scene: this.props.scene || {} as Scene,
    });

    this.props.context.props.booking.scene_list_mapped = mapped_scenes
  }

  async componentDidUpdate(prevProps: SceneFormProps) {
    if (!isEqual(this.props.scene_list, prevProps.scene_list) || this.props.purpose != prevProps.purpose) {
      this.componentDidMount()
    }
  }

  async onValue(field: string, value: any, isValid: boolean) {
    if (!isValid) {
      this.props.context.onValue(field, value, false);
    }

    switch (field) {
      case 'purpose':
        this.setState({ scene: {} as Scene });
        break;
      case 'scene':
        value = this.state.scene_dictionary[value];
        this.segmentResource.segmentTrack('Package Clicked', { scene_id: `${value.id}` })
        break;
    }
    this.setState({ [field]: value } as SceneFormState);
    this.props.context.onValue(field, value, true);
  }

  onAction() { }

  render() {

    return (
      <form>
        <Row collapsed>
          <h4 className="color--primary text-center ">{this.props.label}</h4>
          <Col xs={8} sm={6} smOffset={3} className="center">
            <Radio
              field="purpose"
              value={this.state.purpose}
              columns={this.PURPOSES.length}
              context={this}
            >
              {this.PURPOSES.map(purpose => (
                <TextOption value={purpose} key={purpose} />
              ))}
            </Radio>
          </Col>
        </Row>
        {this.state.purpose ? SceneSelection(this) : null}
        {this.state.scene.id ? SceneDisplay(this) : null}
      </form>
    );
  }
}

class SceneOption extends RadioOption {
  render() {
    const { id, text, field } = this.state;
    const { active } = this.props;
    return (
      <div
        className="input-radio input-radio--innerlabel"
        style={{ margin: '7.5px', width: 'auto' }}
      >
        <input
          id={id}
          type="radio"
          checked={active}
          name={field}
          value={this.props.value}
          onChange={this.onChange}
        />
        <label htmlFor={id}>{text}</label>
      </div>
    );
  }
}

function SceneSelection(context: SceneForm) {
  
  return (
    <Row collapsed className="fadein">
      <h4 className="color--primary text-center">
        {'Okay! What type of shoot?'}
        <span
          className="tooltip"
          data-tooltip-direction="top"
          onMouseEnter={() => {
            context.segmentResource.segmentTrack('Tooltip Hovered', { label: 'what type of shoot' })
          }
          }
        >
          <div className="tooltip__anchor"></div>
          <div className="tooltip__text">
            We will find you photographers qualified for your selected category.
          </div>
        </span>
      </h4>
      <Col xs={12} style={{ textAlign: 'center' }}>
        <Radio
          field="scene"
          value={String(context.state.scene.id)}
          context={context}
        >
          {context.state.scene_list[context.state.purpose] ?
            context.state.scene_list[context.state.purpose].slice(0, context.state.scenesToShow).map(scene => (
              <SceneOption
                value={String(scene.id)}
                text={scene.name}
                key={scene.name}
              />
            )) : null
          }
        </Radio>
        {context.state.scenesToShow == 8 && context.state.scene_list[context.state.purpose].length > 8 ?
          <span 
            className='color--primary type--bold' 
            style={{cursor: 'pointer'}}
            onClick={() => {context.setState({scenesToShow: context.state.scene_list[context.state.purpose].length})} }
            >
              Show More
          </span>
        : null}
      </Col>
    </Row>
  );
}

function SceneDisplay(context: SceneForm) {
  const segmentResource = new SegmentResource()
  return (
    <Row>
      <Col xs={12} sm={10} smOffset={1}>
        <div>
          <div className="scene-image">
            <div
              className="imagebg boxed"
              data-scrim-bottom="8"
              style={{ height: 240 }}
            >
              <div
                className="background-image-holder"
                style={{
                  backgroundImage: `url(${context.state.scene.image})`,
                  opacity: 1,
                }}
              ></div>
              <div className="container content">
                <h3>{context.state.scene.name}</h3>
                <p>{context.state.scene.description}</p>
              </div>
            </div>
          </div>
          <div
            className="text-center type--fine-print"
            style={{ paddingTop: 8 }}
          >
            <p>
              Didn't find what you were looking for? Chat with us&nbsp;
              <a href="javascript:Intercom('showNewMessage')" onClick={() => segmentResource.segmentTrack("Chat Bubble Clicked",{})}>here</a>
            </p>
          </div>
        </div>
      </Col>
    </Row>
  );
}
